.terminal {
  flex: 9;
  overflow-y: auto;
  background-color: #000000;
  color: #000000;
  padding: 35px 45px;
  font-size: 14px;
  line-height: 1.42;
  font-family: "IBM Plex Mono", Consolas, Menlo, Monaco, "Courier New", Courier,
    monospace;
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}

.terminal__line {
  line-height: 2;
  white-space: pre-wrap;
}

.terminal__prompt {
  display: flex;
  align-items: center;
}

.terminal__prompt__label {
  flex: 0 0 auto;
  color: #3ca832;
}

.terminal__prompt__history {
  margin-left: 1rem;
  color: white;
}

.terminal__prompt__input {
  flex: 1;
  margin-left: 1rem;
  display: flex;
  align-items: center;
  color: white;
}

.terminal__prompt__input input {
  flex: 1;
  width: 100%;
  background-color: transparent;
  color: white;
  border: 0;
  outline: none;
  font-size: 14px;
  line-height: 1.42;
  font-family: "IBM Plex Mono", Consolas, Menlo, Monaco, "Courier New", Courier,
    monospace;
}

.loading {
  font-weight: bold;
  display: inline-block;
  font-family: monospace;
  font-size: 30px;
  clip-path: inset(0 3ch 0 0);
  animation: l 1s steps(4) infinite;
}

@keyframes l {
  to {
    clip-path: inset(0 -1ch 0 0);
  }
}
